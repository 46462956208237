import { omit } from 'lodash';

import {
  ProjectResponse,
  ProjectResponseWithCreatedByPopulated,
  ProjectLogResponse,
} from '@workerbase/api/http/project';
import { TriggerGET } from '@workerbase/types/api/rules';

import { Languages } from '@workerbase/domain/common';
import { TriggerTypes } from '@workerbase/domain/rule';

import { Project, ProjectWithCreatedByPopulated } from 'services/types/Project';
import { ProjectLog } from 'services/types/ProjectLog';
import { Trigger } from 'services/types/Rule/Trigger';

const extractCommonProjectFields = (project) => ({
  id: project._id,
  adminRoles: project.adminRoles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) ?? [],
  archiveTasks: project.archiveTasks ?? [],
  roles: project.roles?.map((roleOrId) => (typeof roleOrId === 'string' ? roleOrId : roleOrId?._id)) ?? [],
  rolesRaw: project.roles,
  adminRolesRaw: project.adminRoles,
  config: project.config ?? { taskFilter: [], taskDetailsFilter: [] },
  name: project.name ?? '',
  description: project.description ?? '',
  createdAt: project.meta?.createdAt ?? new Date(),
  taskCount: project.taskCount ?? {},
  language: project.language ?? Languages.EN,
});

export const normalizeProject = (project: Partial<ProjectResponse> & { _id: string }): Project => ({
  ...extractCommonProjectFields(project),
  meta: project.meta,
});

export const normalizeProjectWithCreatedByPopulated = (
  project: Partial<ProjectResponseWithCreatedByPopulated> & { _id: string },
): ProjectWithCreatedByPopulated => ({
  ...extractCommonProjectFields(project),
  meta: project.meta,
});

export const normalizeTrigger = (trigger: TriggerGET): Trigger => {
  const normalizedTrigger: Trigger = {
    ...trigger,
    disabled: !!trigger.disabled,
  };

  if (normalizedTrigger.type === TriggerTypes.INTERNAL_EVENT) {
    return omit(normalizedTrigger, 'resourceId'); // as TriggerInternalEvent
  }

  return normalizedTrigger; // as TriggerExternalEvent
};

export const normalizeProjectLog = ({ _id, level, action, meta, payload }: ProjectLogResponse): ProjectLog => ({
  id: _id,
  level,
  action,
  meta: { createdAt: meta.createdAt || new Date() }, // Question: Can meta.createdAt ever be null or undefined?
  payload: payload || {}, // Question: Can payload ever be null or undefined? If yes then we need to update payload type
});
